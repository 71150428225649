<template>
    <div class="up_content_inner up_manage_invoice">
        <div class="up_main_container">
            <div class="up_page_header">
                <h2 class="up_page_title p-mr-6">Invoices</h2>
            </div>
            <div class="up_inner_container">
                <!--- Show Invoice Table Start --->
                <DataTable class="p-datatable p-mb-2" :paginator="true" stripedRows :value="allInvoiceData.data" :rowHover="true" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="" :loading="allInvoiceData.loading" responsiveLayout="scroll" removableSort>
                    <template #loading>
                        Loading invoices data. Please wait.
                    </template>
                    <template #header>
                        <div class="p-d-flex p-jc-end">
                            <div class="p-d-flex p-align-center">
                                <span class="p-input-icon-left sup_search_box">
                                    <i class="pi pi-search" />
                                    <InputText v-model="allInvoiceData.search" placeholder="Search Invoices" @keyup.enter="fetchAllInvoice('search')"/>
                                </span>
                                <Button v-if="allInvoiceData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllInvoice('resetFilters')"/>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No data available in table.
                    </template>
                    <Column field="invoiceNumber" header="Invoice #" style="text-align: center" :sortable="true">
                        <template #body="slotProps">
                            <Button type="button" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-outlined p-button-gray p-mr-2 p-f" @click="$router.push({
                                name: 'CustomerInvoiceDetails',
                                params: {
                                    invoiceData: JSON.stringify(slotProps.data)
                                } 
                            })" v-tooltip="'View More'" />
                            <span class="up_invoice_number">
                                {{slotProps.data.invoiceNumber}}
                            </span>
                        </template>
                    </Column>
                    <Column field="siteName" header="Website" headerStyle="width: 20rem" :sortable="true">
                        <template #body="slotProps">
                            <div class="p-d-flex">
                                <div class="up_website_info">
                                    <span class="sup_site_info p-d-block">{{slotProps.data.Website.siteName}}</span>
                                    <a :href="'https://' + [slotProps.data.Website.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.Website.siteUrl}}</a>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="orderType" header="Type" style="text-align: center" :sortable="true" />
                    <Column field="chargedAmount" header="Rate" style="text-align: right" :sortable="true">
                        <template #body="slotProps">
                            {{'$' + slotProps.data.chargedAmount}}
                        </template>
                    </Column>
                    <Column field="billingPeriod" header="Billing Period" style="text-align: center" :sortable="true">
                        <template #body="slotProps">
                            {{usFormatDate(slotProps.data.billingPeriod.split(" ")[0]) + " " + usFormatDate(slotProps.data.billingPeriod.split(" ")[1])}}
                        </template>
                    </Column>
                    <Column field="status" header="Status" style="text-align: center" :sortable="true">
                        <template #body="slotProps">
                            <span :class="['up_item_status ', slotProps.data.orderStatus === 'paid' ? 'up_item_status_success' : 'up_item_status_danger']">{{slotProps.data.orderStatus === 'paid' ? 'Paid' : 'Failed'}}</span>
                        </template>
                    </Column>
                </DataTable>
                <!--- Show Invoice Table End --->
            </div>
        </div>
    </div>
</template>

<script>
import { userPortalInvoice } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import customerLogout from '../../../../helper/userLogout/customerLogout';
import { usFormatDate } from '../../../../helper/utility/utility';
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount } from 'vue';

export default {
    name: "InvoiceList",
    setup(){
        //Application store call
        const store = useStore();

        //Invoice initial data storing state
        const allInvoiceData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            search: null,
            sortBy: 'both'
        });

        /**
         * 
         * fetchAllInvoice() function call on component mount
         * 
         */
        onBeforeMount(async() => {
            fetchAllInvoice();
        });

        /**
         * 
         * Invoice data fetch, search and reset search
         * userPortalInvoice - Invoice api variable
         * customerLogout() - If any un-authorized error
         * 
         */
        const fetchAllInvoice = async (load) =>{
            if(load === "resetFilters"){
                allInvoiceData.search = "";
            }
            try{
                allInvoiceData.loading = !allInvoiceData.loading;
                const response = await axios.get( userPortalInvoice, {
                    params:{
                        status: 'all',
                        search: allInvoiceData.search
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allInvoiceData.data = response.data.data.invoice;
                allInvoiceData.total = response.data.data.total;
                allInvoiceData.loading = !allInvoiceData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        return{
            allInvoiceData,
            fetchAllInvoice,
            usFormatDate
        }
    }
}
</script>

<style>

</style>